(function($) {
	$(document).ready(function(){
		
		// Smooth Scroll
		$('#menu-main-top-menu a').on('click', function(e) {
			e.preventDefault();
			var url = 'https?:\/\/[^\/$\s]+\/';
			// 1. Check if href contains url
			
			// 2. Remove url and add #, to make the link refer to an ID
			if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
				var target = $(this.hash);
				target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
				if (target.length) {
					$('html, body').animate({
					scrollTop: target.offset().top
					}, 800);
					return false;
				}
			}
	  	});

	  	// Child-menu-services
	  	$('.child-menu-services').on( 'click', function(e) {
	  		e.preventDefault();
	  		// 1. Select the data-displaytoggle of the menu item
	  		var id 		= '#' + ($(this).data('displaytoggle')),
	  			link 	= $(this);
	  		// 2. Display/hide the corresponding content of the clicked menu item
	  		$( link ).addClass( 'services-active' );
	  		$( '.child-menu-services' ).removeClass("services-active");
	  		$( link ).addClass( 'services-active' );
	  		$( '.child-services' ).addClass("hidden");
	  		$( id ).removeClass( 'hidden' );
	  	});
	  	// Child-menu-products
	  	$('.child-menu-products').on( 'click', function(e) {
	  		e.preventDefault();
	  		// 1. Select the data-displaytoggle of the menu item
	  		var id = '#' + ($(this).data('displaytoggle'));
	  		// 2. Display/hide the corresponding content of the clicked menu item
	  		$( '.child-products' ).addClass("hidden");
	  		$( id ).removeClass( 'hidden' );
	  	});

	  	/* 		MAP
	  	-----------------------------------------*/
	  	//Fix for IE
			if (navigator.appName == 'Microsoft Internet Explorer' ||  !!(navigator.userAgent.match(/Trident/) || navigator.userAgent.match(/rv 11/)) || $.browser.msie == 1) {
				document.getElementById("mapwrap").style.display = "none";
				document.getElementById("mapfallback").style.display = "block";
			}
			//Fix for rest non supportet browsers
			if (!mapboxgl.supported()) {
				$("#mapwrap").css('display', 'none');
				$("#mapfallback").css('display', 'block');
			} else {

				mapboxgl.accessToken = 'pk.eyJ1IjoiZGFrbmlnaHQiLCJhIjoiazkwSjJqSSJ9.ght0Fub2UHczFlkQ0eHzZA';
				var map = new mapboxgl.Map({
					container: 'map', // container id
					style: 'mapbox://styles/daknight/citb6a1rl000l2imnw6ltlbqq' //hosted style id
				});
				map.on("render", function () {
					if (map.loaded()) {
						$(".maploading").animate({
							zoom: '0.6'
						}, 2000, function () {
							$(this).css('display', 'none')
						});
					}
				});
				$("#scrolltoggle").click(function () {
					$("#scrolltoggle .ctn").toggle();
					$(".mobileScrollBlock").toggle();
				});
			}

	});
})( jQuery );